// design v3 variables------------------------------------------------------

$hunch-black: #232426;
$hunch-white: #ffffff;

$hunch-gray1: #FAFAFA;
$hunch-gray2: #F2F4F5;
$hunch-gray3: #EDEFF0;
$hunch-gray4: #E3E5E6;
$hunch-gray5: #D4D5D6;
$hunch-gray6: #BBBDBF;
$hunch-gray7: #7D7E80;
$hunch-gray8: #4A4B4D;

$hunch-green05: #E1FFE2;
$hunch-green1: #D0FFCC;
$hunch-green2: #85F297;
$hunch-green3: #14D172;
$hunch-green4: #09B35E;
$hunch-green5: #0E8C4D;

$hunch-orange1: #FFEFBF;
$hunch-orange2: #FFE28C;
$hunch-orange3: #FFD241;
$hunch-orange4: #FFC20D;
$hunch-orange5: #F2920C;

$hunch-red1: #FFDCD7;
$hunch-red2: #FAA69E;
$hunch-red3: #F05142;
$hunch-red4: #D93021;
$hunch-red5: #991408;

$hunch-blue1: #D6E7FF;
$hunch-blue2: #99BBFF;
$hunch-blue3: #3264FF;
$hunch-blue4: #0B3ED9;
$hunch-blue5: #0029A6;

$hunch-box-shadow-color: #00000014;

// icons

$ico-font-family: "hunch" !default;

$ico-Hunch: "\e94b";
$ico-Profile-Active: "\e95c";
$ico-Profile-Default: "\e95d";
$ico-Automation-Active: "\e952";
$ico-Automation-Default: "\e953";
$ico-Catalogs-Active: "\e954";
$ico-Catalogs-Default: "\e955";
$ico-Home-Active: "\e956";
$ico-Home-Default: "\e957";
$ico-Library-Active: "\e958";
$ico-Library-Default: "\e959";
$ico-Manage-Active: "\e95a";
$ico-Manage-Default: "\e95b";


[class^="ico-"],
[class*=" ico-"] {
    font-family: '#{$ico-font-family}' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    text-decoration: none !important;
    color: $hunch-black;
    cursor: pointer !important;

    &:hover {
        color: $hunch-black;
    }

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ico-Automation-Active {
    &:before {
        content: $ico-Automation-Active;
    }
}

.ico-Automation-Default {
    &:before {
        content: $ico-Automation-Default;
    }
}

.ico-Catalogs-Active {
    &:before {
        content: $ico-Catalogs-Active;
    }
}

.ico-Catalogs-Default {
    &:before {
        content: $ico-Catalogs-Default;
    }
}

.ico-Home-Active {
    &:before {
        content: $ico-Home-Active;
    }
}

.ico-Home-Default {
    &:before {
        content: $ico-Home-Default;
    }
}

.ico-Library-Active {
    &:before {
        content: $ico-Library-Active;
    }
}

.ico-Library-Default {
    &:before {
        content: $ico-Library-Default;
    }
}

.ico-Manage-Active {
    &:before {
        content: $ico-Manage-Active;
    }
}

.ico-Manage-Default {
    &:before {
        content: $ico-Manage-Default;
    }
}

.ico-Profile-Active {
    &:before {
        content: $ico-Profile-Active;
    }
}

.ico-Profile-Default {
    &:before {
        content: $ico-Profile-Default;
    }
}

.ico-Hunch {
    &:before {
        content: $ico-Hunch;
    }
}

.ico-Profile-Active {
    &:before {
        content: $ico-Profile-Active;
    }
}

.ico-Profile-Default {
    &:before {
        content: $ico-Profile-Default;
    }
}

// design v3 styles---------------------------------------------------------

body {
    -webkit-font-smoothing: antialiased !important;

    nav {
        &.navbar-dark {
            background: $hunch-black;

            .navbar-nav {
                .nav-link {
                    font-family: Inter;
                    opacity: 1;
                    color: $hunch-gray6;
                    transition: 0.25s;

                    .nav-link-icon * {
                        fill: $hunch-gray6;
                        transition: 0.25s;
                    }

                    &:hover,
                    &:focus {
                        color: $hunch-white !important;

                        .nav-link-icon * {
                            fill: $hunch-white !important;
                        }
                    }
                }
            }
        }
    }



    .hunch-manage {

        .hunch-drawer-side {
            background: $hunch-gray2;
        }

        .table-container-borders {
            border: 0 !important;

            .ngx-datatable,
            .skeleton-table-caontainer {
                border-top: 1px solid $hunch-gray3;
                border-bottom: 1px solid $hunch-gray3;
                box-shadow: none;
                border-radius: 0;

                &.material.hunch-datatable.hunch-manage-table .datatable-row-even .body-cell {
                    background-color: transparent;
                    &.static-col{
                        background-color: $hunch-white;
                    }
                }
                &.material.hunch-datatable.hunch-manage-table .datatable-body-row.active .body-cell {
                    background-color: #e5faef;
                }
            }
        }


        .header-cell {
            background-color: $hunch-white !important;
            color: $hunch-black;
            border-color: $hunch-gray4 !important;
            border-bottom: 1px solid $hunch-gray4 !important;
            svg{
                height:14px !important;
                width:14px !important;
                margin-top:-1px;
            }
        }

        // .hunch-integration-type-fill-color {
        //     color: $hunch-green3;
        // }

        // .google-integration-type-fill-color {
        //     color: $hunch-orange3;
        // }

        .body-cell {
            border-color: $hunch-gray4 !important;
        }

        .footer-cell {
            border-bottom: 0 !important;
            border-color: $hunch-gray4 !important;
        }

        .hunch-datatable.hunch-manage-table .sort-btn{
            &:after{
                top:10px !important;
            }
            &.datatable-icon-up.sort-asc:after {
                color: $hunch-gray3 !important;
            }
        }

        .hunch-datatable.hunch-manage-table .sort-btn.datatable-icon-down.sort-desc:after {
            color: $hunch-gray3 !important;
            top:10px !important;
        }

        .hunch-datatable.hunch-manage-table .datatable-header {
            background: $hunch-white;
        }

        // forms ------------------------------------------------------------

        &.hunch-angular-module .select--dark .ng-select-container,
        &.cdk-overlay-container .select--dark .ng-select-container {
            border-radius: 6px !important;
            transition: background-color 0.1s ease-in 50ms;
            &:hover{
                background-color: $hunch-gray5 !important;
            }
            .ng-placeholder{
                color:$hunch-black;
                height:28px !important;
            }
        }

        .off-hunch,
        .off-google,
        .input--inner input[type="text"],
        .filter-item {
            border-radius: 6px;
        }

        &.hunch-angular-module .select--dark .ng-dropdown-panel,
        &.cdk-overlay-container .select--dark .ng-dropdown-panel,
        .date-modal {
            border-radius: 5px;
            border: 1px solid transparent !important;
            margin-top: 10px;
            // box-shadow: 0px 0px 0px 1px #0000000D, 0px 3px 6px 0px #0000001A, 0px 9px 24px 0px #00000033 !important;
            button.btn--solid{
                color:$hunch-black !important;
            }
        }

        &.hunch-angular-module .select--dark.select--quick-range .ng-dropdown-panel {
            border-radius: 0 12px 12px 0 !important;

            .ng-option {
                &:last-child {
                    border-radius: 0 0 12px 0;
                }
            }
        }

        &.hunch-angular-module .select--dark.ng-select-bottom .ng-dropdown-panel {
            margin-top: 1px;
        }

        &.cdk-overlay-container .select--dark .ng-dropdown-panel,
        .date-modal {
            border-radius: 12px 0 0 12px !important;
        }

        .preset--footer {
            border-radius: 0 0 12px 12px !important;
        }


        // buttons overide -----------------------------------------------------------
        .btn--solid {
            border-radius: 6px;

            &.btn--solid_v3 {
                height: 48px;
                min-width: 160px !important;
                font-size: 14px;
                font-weight: 700;
                border-radius: 12px;

                &.btn--solid_green {
                    color: $hunch-black;
                }
            }
        }

        .btn--solid_facebook {
            background-color: #3264FF;

            .icon--left {
                * {
                    &:nth-child(2) {
                        fill: #4267B2;
                    }
                }
            }

            &.btn_insert_ad {
                background-color: #15d173;
                margin-right: 16px;
                &:hover{
                    background-color: $hunch-green4;
                }

                span {
                    color: $hunch-black;
                }

                .icon--left {
                    * {
                        fill: $hunch-black !important;
                    }
                }

                svg-icon {
                    margin-top: -2px !important;
                }
            }
        }


        //table scroll ----------------------------------------------------------


        .table-scroller {
            height: 15px;
            background-color: $hunch-white;

            &::-webkit-scrollbar-thumb {
                border: 5px solid $hunch-white !important;
            }
        }

        ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            box-shadow: none;
            border-radius: 10px;
            background-color: $hunch-white;
        }

        ::-webkit-scrollbar {
            height: 4px;
            background-color: transparent;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: $hunch-gray6;
        }


        // filter-option modal -------------------------------------------------------------------

        .filter-option {
            border-radius: 12px;
            border: 0 !important;

            .filter-option--header {
                background-color: $hunch-gray6;
                border-radius: 12px 12px 0 0;
            }
        }


        // modal ------------
        .hunch-modal-container {
            border-radius: 12px;
            box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.14);

        }

        .filter-item {
            background-color: $hunch-gray1;
            color: $hunch-black;
        }

        .filter-item-name {
            background-color: $hunch-gray1;
            color: $hunch-black;
        }

        .svg.close-icon path {
            fill: $hunch-black !important;
        }

        // helpers -------------------------------------------------------------------

        .hidden {
            visibility: hidden;
        }

        .w--100.table-scroller {
            // scrollbar-width: none;
        }
    }

}

.loading-filters{
    height:100px;
    text-align: center;
    div{
        vertical-align: bottom;
    }
}

// additional overriding old styles

// fix for left nav
left-menu {
    .hunch-drawer-static-container {
        top: 91px !important;
    }
}

// fix for tabs and buttons in tab container
.tabs-container {
    .btn--tab {
        font-size: 14px;
    }

    .btn--solid {

        &.btn--solid_facebook {
            svg {
                margin-top: -11px !important;
            }
        }

        &.btn--solid_snapchat {
            svg {
                zoom: 1.5;
                margin-top: -6px !important;
            }
        }
    }
}

// fix for status switch in table
toggle-status-table-body-cell {
    font-size: 14px !important;
    line-height: 26px !important;

    mat-slide-toggle {
        vertical-align: middle;

        label {
            margin-right: 0;
        }
    }
}

table-body-name-cell {
    .item-type .cercle-container {
        border-radius: 0 !important;
        width: 22px !important;
        height: 22px !important;
        border-radius: 50% !important;
        margin-top: -1px;

        svg-icon {
            zoom: 1.2;
            // margin-left: -3px;
            // margin-top: -3px;

            #Layer_1 {
                rect {
                    fill: transparent !important;
                }

                path {
                    fill: #4267b2 !important;
                }

                &.hunch-icon--automated {
                    path {
                        fill: $hunch-green3 !important;
                    }
                }

                &.hunch-icon--post-boosting.snapchat {
                    path {
                        fill: $hunch-orange4 !important;
                    }
                }

                &.fb-icon {
                    path {
                        fill: #4267b2 !important;

                        &:nth-child(2) {
                            fill: $hunch-white !important;
                        }

                    }
                }
            }

            &.meta-icon {
                top: 5px;
                z-index: 3689;
                position: absolute;
            }
        }
    }
}

// fix for select items with icons
manage-duplicate-campaign-modal ng-select.select--dark .ng-value #Layer_1 {
    vertical-align: top;
}

// fix for loader animation
.lds-ellipsis {
    display: block !important;
}

// fix for toaster
#toast-container>div {
    opacity: .95 !important;
    border-radius: 12px !important;
}

// fix for delete batch button

delete-batch-action {
    font-size: 14px;
    line-height: 14px;

    button {
        height: 28px;
    }
}

// fix for select icons{
.ng-value-label {
    svg-icon {
        max-height: 10px !important;
    }
}

// fix for dropdown panel items

table-filter-level {
    ng-select {
        .ng-option {
            height: auto;
        }
    }
}



// search icon size

hunch-input.search {
    input{
        border:1px solid transparent !important;
    }
    .input--wrapper .input--inner .input--icon svg {
        height: 12px !important;
        vertical-align: baseline;
    }
}


//calendar fixes

bs-daterangepicker-inline {
    bs-days-calendar-view {
        &:nth-child(2) {
            padding-left: 15px;
        }
    }

}

// hover actions

.h--100.hover-show {
    top: -13px;
    position: absolute;
}


// typography
.size--5 {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: $hunch-gray8;
}

.font--weight_semi {
    font-weight: 600;
}

.text-light {
    color: $hunch-gray8 !important;
}