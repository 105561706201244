/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import 'main-bundle.scss';
@import './assets/styles/theme-v3.scss';

.hunch-angular-module.hunch-manage {
    * {
        box-sizing: border-box;
        font-family: "Inter", sans-serif;
    }

    svg-icon svg {
        height: 12px;
        width: 12px;
        // path,
        // rect {
        //     fill: $grey-6 !important;
        // }
    }

    ng-select .ng-value-container .ng-value svg {
        position: relative;
        top: 2px;
    }

    .btn--solid.btn--solid_grey {
        background-color: $grey-1;
        &:hover,
        &:active {
            background-color: $grey-2;
        }
    }

    button.btn--solid.btn--solid_facebook, button.btn--solid.btn--solid_snapchat {
        svg-icon,
        svg {
            height: 16px;
            width: 16px;
        }
    }
    .btn--solid_snapchat {
        min-width: auto;
        background-color: #FFD241;

        &:hover,
        &:active {
            background-color: #FFE28C;
        }

        &:last-child{
            margin-left:16px;
        }

        span {
            color: $black;
        }

        .icon--left {
            margin-right: 8px;
            * {
                fill: transparent;
                path:nth-child(2){
                    fill: $black !important;
                }
            }
        }

    }

    .modal-header {
        padding: 0 !important;
    }

    //>>>>>>scss AngularJS coalision
    .icon-placeholder,
    .icon-box {
        display: inline-flex !important;
        align-self: center;
        > svg-icon {
            display: flex;
            svg {
                position: inherit;
            }
        }
    }

    .ng-option {
        min-height: 29px !important;
    }

    ngx-datatable {
        .datatable-row-group.datatable-row-left {
            datatable-body-cell:nth-child(1) {
                .datatable-body-cell-label {
                    display: flex;
                }
            }
        }
        &.bootstrap:not(.cell-selection) .datatable-body-row:hover,
        &.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
        
            .body-cell {
                background: $grey-1 !important;
            }
        }

        // .datatable-body{
        //     background:$white;
        // }
    }
    //manage table-ad-thumbnail modiifications
    ngx-datatable.ad-table {
        .body-cell {
            line-height: 50px;
        }
        cell-format-compound-display-value > .compound-main {
            padding-top: 6px;
        }
        toggle-status-table-body-cell{
            .body-cell{
                mat-slide-toggle{
                    // margin-top: 13px !important;
                }
            }
        }
    }

    //<<<<<<scss AngularJS coalision

    //dropdowns - table actions >>>>
    .select--dark.select--manage-1.columns-options.ng-select-opened.ng-select-searchable.ng-select-bottom .ng-dropdown-panel {
        margin-top: 11px !important;
    }

    .select--dark.select--manage-1.columns-options.ng-select-opened.ng-select-searchable.ng-select-bottom .ng-select-container .ng-value-container .ng-input {
        position: absolute;
        top: -8px !important;
    }

    .select--dark.select--manage-1.select--manage-select-left.select--radio .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .select--radio-checkbox,
    .select--dark.select--radio .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup .select--radio-checkbox {
        right: auto;
    }

    .select--dark.select--manage-1.select--manage-select-left.select--radio .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .select--radio-option,
    .select--dark.select--radio .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup .select--radio-option {
        padding-left: 20px;
        padding-right: 0;
    }

    ng-select.select--dark.select--manage-1 > .ng-select-container > .ng-value-container {
        .ng-placeholder {
            font-weight: 400 !important;
        }
        .ng-value > span {
            font-weight: 400 !important;
        }
    }
    //dropdowns - table actions <<<<<<

    //date-picker >>>>
    .bs-datepicker .bs-datepicker-container .bs-datepicker-head {
        height: 30px;
        background-color: $white;

        > bs-datepicker-navigation-view > button {
            &.current:nth-child(3) {
                > span {
                    font-weight: 400 !important;
                }
            }

            > span {
                color: $grey-6 !important;
            }
        }
    }

    .bs-datepicker .bs-datepicker-container .bs-datepicker-body thead tr th {
        color: $grey-6;
    }

    .bs-datepicker .bs-datepicker-container .bs-datepicker-body thead tr {
        background-color: $white;
    }

    .bs-datepicker .bs-media-container {
        padding: 15px;
    }
    .bs-datepicker-multiple:first-child {
        margin-right: 5px;
    }

    .bs-datepicker .bs-datepicker-container .bs-datepicker-body tbody tr td span.selected {
        background-color: $green-3;
    }

    .bs-datepicker-body table td:before,
    .bs-datepicker-body table td span:before {
        top: 0px;
        bottom: 0px;
    }

    .bs-datepicker .bs-datepicker-container .bs-datepicker-body tbody tr td span.is-other-month {
        color: $white !important;
    }

    .bs-datepicker .bs-datepicker-container .bs-datepicker-body tbody tr td span {
        height: 30px;
    }

    .bs-datepicker .bs-datepicker-container .bs-datepicker-head {
        .previous {
            padding: 0;
            &:hover,
            &:active {
                background-color: hsla(0, 0, 0, 0);
            }
        }
        .next {
            padding: 0;
            &:hover,
            &:active {
                background-color: hsla(0, 0, 0, 0);
            }
        }
        button[disabled] {
            background-color: hsla(0, 0, 0, 0);
        }

        button.next,
        button.previous {
            span {
                transform: scale3d(1.4, 1.2, 1);
                position: relative;
                top: -2px;
            }
        }
    }
    //date-picker <<<<<<

    //ng-select spec
    ng-select.select--dark.select--manage-1 {
        height: 28px;
        .ng-select-container {
            height: 28px;
        }

        .ng-value-container .ng-value .ng-value-img {
            width: 100%;
            max-width: 10px;
            height: 100%;
            // max-height: 10px;
            margin-right: 8px;
        }

        .ng-value svg {
            height: 10px;
            width: 10px;
            top: 0;
        }

        .ng-select-container {
            height: 28px !important;
            max-height: 28px !important;
            z-index: 1;
            .ng-value-container {
                height: 28px !important;
                max-height: 28px !important;
                z-index: 1;
                .ng-value {
                    height: 28px !important;
                    max-height: 28px !important;
                    z-index: 1;
                }
                .ng-input {
                    height: 28px !important;
                    max-height: 28px !important;
                    z-index: 1;
                }
            }
        }

        .no-hover:hover {
            background-color: $white !important;
        }

        .ng-option.border-bottom-0 {
            border-bottom: 0 !important;
        }
    }
}
